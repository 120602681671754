* {
  /* height: 100%; */
  /* background-color: black; */
}

.bg {
  background-image: url("../../assets/images/big/auth-bg4.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
